import React from "react";
import { Button, Typography, Box, Grid, Link } from "@mui/material";
import BlueSmallHead from "./InfoBoxes/BlueSmallHead";
import { buttonStyleClanky } from "../Styles/constants";
import { motion } from "framer-motion";
import { redirect } from "../Classes/history";

export default function Article({
  data,
  index,
  sxProps = {},
  widths = { xs: 24, sm: 12, md: 8, lg: 8 },
  sxButton = {},
  sxImg = { xs: "block", sm: "block", md: "block" },
  maxWidth = { xs: "none", sm: "280px" },
  bluePanel = true,
}) {
  return (
    <Grid
      item
      xs={widths.xs}
      sm={widths.sm}
      md={widths.md}
      lg={widths.lg}
      component={Box}
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sxProps,
      }}
      key={"Clanek" + index}
    >
      {bluePanel && <BlueSmallHead icon={data.icon} title={data.description} />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: maxWidth,
        }}
      >
        {data.title}
        <Box>{data.text}</Box>
        {data.buttonSpace && <div style={{ height: "50px" }} />}
        {data.button && (
          <Button
            component={motion.button}
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 40 }}
            variant={"contained"}
            sx={{ ...buttonStyleClanky, ...sxButton, minWidth: "110px" }}
            onClick={
              data.onClick
                ? data.onClick
                : () => {
                    if (data.id) {
                      redirect(`/clanek?title=${data.id}`);
                    } else {
                      redirect(`${data.redirect}`);
                    }
                  }
            }
          >
            <a
              style={{
                textDecoration: "none",
                height: "100%",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
              }}
              href={data?.id ? `/clanek?title=${data.id}` : `${data.redirect}`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {data.buttonText}
            </a>
          </Button>
        )}
        {data.image && data.imagePhoto ? (
          <Box
            sx={{
              display: sxImg,
              marginBottom: { xs: "10px", sm: "-20px", md: "40px" },
            }}
          >
            <picture>
              <source srcSet={data.imagePhoto} type="image/webp" />
              <img
                alt="Article Image"
                src={data.imagePhotoPng ? data.imagePhotoPng : data.imagePhoto}
                style={{
                  marginTop: "40px",

                  width: "100%",
                  aspectRatio: 1 / 0.6507,
                }}
              />
            </picture>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Grid>
  );
}
