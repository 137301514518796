import React, { lazy } from "react";
import Loadable from "../Main/Components/Modal/Loadable";

const ShoppingCart = Loadable(
  lazy(() => import("../Main/Pages/ShoppingCart/ShoppingCart"))
);
const Controller = Loadable(
  lazy(() => import("../Main/Pages/VysledkyVyhledavani/Controller"))
);
const DetailShoppingCart = Loadable(
  lazy(() => import("../Main/Pages/ShoppingCart/Detail/DetailShoppingCart"))
);
const Redirect = Loadable(
  lazy(() => import("../Main/Pages/VysledkyVyhledavani/Redirect/Redirect"))
);

const shopRoutes = [
  {
    path: "/shop",
    element: <ShoppingCart />,
    navbar: false,
  },
  {
    path: "/order",
    element: <Controller />,
    navbar: false,
    elevation: true,
  },
  {
    path: "/redirect",
    element: <Redirect />,
    navbar: false,
    elevation: true,
  },
  {
    path: "/detailshop",
    element: <DetailShoppingCart />,
    navbar: false,
  },
];

export default shopRoutes;
