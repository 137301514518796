import React, { Component } from "react";
import { ReactComponent as Gopay } from "../../../Design/BankLogos/Gopay.svg";
import { ReactComponent as Mastercard } from "../../../Design/BankLogos/Mastercard.svg";
import { ReactComponent as Visa } from "../../../Design/BankLogos/Visa.svg";
import { Grid, Box, Typography, SvgIcon } from "@mui/material";
import LogoJS from "../../../Design/Logo";
import { darkestBlue } from "../../Styles/constants";

const SCREEN_SIZE = 24;

const LogoVectorJS = ({
  textColor = "rgb(0,0,0)",
  logoColor = darkestBlue,
  showText = true,
}) => {
  const editedIcon = React.cloneElement(
    <LogoJS textColor={textColor} logoColor={logoColor} showText={showText} />,
    {
      style: {
        height: "24px",
        maxWidth: "127px",
        fill: "white",
      },
    }
  );
  return <>{editedIcon}</>;
};

export default class PaymentsFooter extends Component {
  render() {
    return (
      <Grid
        container
        spacing={0}
        columns={SCREEN_SIZE}
        sx={{ marginTop: "5px", marginBottom: "30px" }}
      >
        <Grid item xs={24}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                mt: "8px",
                display: { xs: "none", sm: "flex", md: "flex" },
              }}
            >
              <LogoVectorJS />
            </Box>
            <Visa
              style={{
                maxWidth: "70px",
                maxHeight: "35px",
                marginRight: "10px",
              }}
            />
            <Mastercard style={{ maxWidth: "80px", maxHeight: "45px" }} />
            <Gopay style={{ maxWidth: "180px", maxHeight: "40px" }} />
          </Box>
        </Grid>
      </Grid>
    );
  }
}
