import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/Main/Classes/App";
import reportWebVitals from "./reportWebVitals";

try {
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
} catch (err) {}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);

reportWebVitals();
