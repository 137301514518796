/*
true = show state
false = hide state
*/
export function setWarningState(state) {
  localStorage.setItem("warning", state);
}

export function getWarningState() {
  const state = localStorage.getItem("warning");
  if (state === null) return false;
  if (state === "false") return false;
  return true;
}
