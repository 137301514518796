import { styled } from "@mui/material/styles";
import {
  lightestBlue,
  darkestBlue,
  appRed,
  appRedLight,
} from "../../../Styles/constants";
import { TextField } from "@mui/material";

export const SCREEN_SIZE = 24;
export const MainTextField = (props) => {
  return props.detail === "true" ? (
    <MainTextFieldDetailStyled {...props} />
  ) : (
    <MainTextFieldStyled {...props} />
  );
};

export const MainTextFieldStyled = styled(TextField)({
  //"& label.Mui-focused": lightestBlue,
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    height: "18px",
    maxHeight: "18px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: `2px solid ${darkestBlue}`,
      borderRadius: "6px",
      height: "55px",
      maxHeight: "55px",
    },
    "&:hover fieldset": {
      border: `2px solid ${lightestBlue}`,
      borderRadius: "6px",
    },
    "&.Mui-focused fieldset": {
      borderColor: lightestBlue,
    },
  },
});

export const MainTextFieldDetailStyled = styled(TextField)({
  //"& label.Mui-focused": appRedLight,
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    height: "18px",
    maxHeight: "18px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: `2px solid ${appRed}`,
      borderRadius: "6px",
      height: "55px",
      maxHeight: "55px",
    },
    "&:hover fieldset": {
      border: `2px solid ${appRedLight}`,
      borderRadius: "6px",
    },
    "&.Mui-focused fieldset": {
      borderColor: appRedLight,
    },
  },
});

export const sxTextField = { width: "90%" };
export const buttonProps = {
  height: "56px",
  maxHeight: "56px",
  width: "100%",
  borderRadius: "10px",
  backgroundColor: `${appRed}`,
  "&:hover": {
    backgroundColor: `${appRed}`,
  },
};

export const textFieldLabel = {
  fontFamily: "RawlineBold",
  fontSize: 12,
  marginBottom: "4px",
};

export const buttonText = {
  fontFamily: "MonserratBold",
  fontSize: 16,
  textTransform: "none",
};

export const centerGridContent = {
  align: "center",
  justify: "center",
  alignItems: "center",
};

export const boxContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  justifyItems: "center",
  width: "90%",
  textAlign: "left",
};
export const boxText = {
  width: "100%",
  textAlign: "left",
};
