import React, { lazy } from "react";
import Loadable from "../Main/Components/Modal/Loadable";

const TestPaygate = Loadable(lazy(() => import("../Main/Pages/TestPaygate")));
const MainPage = Loadable(lazy(() => import("../Main/Pages/MainPage")));
const DotazyClanky = Loadable(lazy(() => import("../Main/Pages/DotazyClanky")));
const KontaktPage = Loadable(lazy(() => import("../Main/Pages/KontaktPage")));
const CenikPage = Loadable(lazy(() => import("../Main/Pages/CenikPage")));
const ClanekView = Loadable(lazy(() => import("../Main/Pages/ClanekView")));
const AppPage = Loadable(lazy(() => import("../Main/Pages/AppPage")));
const FirmyPage = Loadable(lazy(() => import("../Main/Pages/FirmyPage")));

const baseRoutes = [
  {
    path: "/",
    element: <MainPage />,
    elevation: true,
  },
  {
    path: "/dotazy",
    element: <DotazyClanky />,
  },
  {
    path: "/kontakt",
    element: <KontaktPage />,
  },
  {
    path: "/cenik",
    element: <CenikPage />,
  },
  {
    path: "/testgate",
    element: <TestPaygate />,
  },
  {
    path: "/clanek",
    element: <ClanekView />,
  },
  {
    path: "/app",
    element: <AppPage />,
  },
  {
    path: "/firmy",
    element: <FirmyPage />,
  },
];

export default baseRoutes;
