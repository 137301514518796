import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { darkestBlue } from "../../Styles/constants";

export default function Loading({
  loading = false,
  text = "Navazuji spojení",
}) {
  return (
    <Backdrop
      sx={{
        backgroundColor: "rgba(0,0,0,0.85)",
        color: darkestBlue,
        zIndex: (theme) => theme.zIndex.drawer + 10000,
      }}
      open={loading}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <CircularProgress />
        <Typography
          sx={{
            fontFamily: "RawlineMedium",
            fontSize: "19px",
            color: "white",
            marginTop: "10px",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Backdrop>
  );
}
