import React, { Component, Suspense, lazy, useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom"; //BrowserRouter as
import history from "./history";
import routes from "../../Routes/routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import themeFile from "../../Design/themes";
//import NavBar from "../Components/Navbars/NavBar";
import { Box, CssBaseline, Typography } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import ScrollUp from "../Pages/ShoppingCart/Components/ScrollUp";
import Loading from "../Components/Modal/Loading";
import LoadingPage from "../Components/Modal/LoadingPage";
import LoadableInvisible from "../Components/Modal/LoadableInvisible";
import {
  appRed,
  appRedLight,
  darkestBlue,
  lightestBlue,
} from "../Styles/constants";
import { getWarningState, setWarningState } from "./Warning";
import { Motion } from "../Components/Motion";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

const NavBar = LoadableInvisible(
  lazy(() => import("../Components/Navbars/NavBar"))
);

const theme = createTheme(themeFile);

const border = "1px solid #D9D9D9";

const BaseRoute = ({ exact, path, navbar, component, elevation }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (
        <div>
          <CssBaseline />
          {navbar !== false && <NavBar elevation={elevation} />}
          {navbar !== false && (
            <div style={{ minHeight: 50, maxHeight: 50, height: 50 }} />
          )}
          {component}
        </div>
      )}
    />
  );
};

function App() {
  const [show, setShow] = useState(getWarningState());

  useEffect(() => {
    setWarningState(show);
  }, [show]);

  const Warning = ({}) => (
    <div style={{ display: "flex", zIndex: 11000 }}>
      {show && (
        <Box
          sx={{
            maxWidth: "380px",
            minWidth: "240px",
            minHeight: "120px",
            boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.3)",
            borderRadius: `17px 17px 0px 17px`,
            bgcolor: "white",
            position: "fixed",
            bottom: "60px",
            right: 0,
            m: "20px",
            border: border,
            zIndex: 11000,
            cursor: "pointer",
          }}
          onClick={() => {
            setShow((prev) => !prev);
          }}
        >
          <Typography
            sx={{ p: "20px", fontSize: "13px", fontFamily: "RawlineMedium" }}
          >
            Pozor na podvodné SMS informující o údajném dluhu. Pokud se s
            podezřelou SMS setkáte, neváhejte nás kontaktovat prostřednictvím
            emailu&#32;<b>podpora@metastream.cz</b>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: "fixed",
          right: 0,
          bottom: 0,
          display: "flex",
          zIndex: 11000,
        }}
      >
        <Motion>
          <Box
            sx={{
              height: "50px",
              width: "50px",
              bgcolor: "white",
              border: `1px solid ${appRedLight}`,
              m: "20px",
              borderRadius: `17px 0px 17px 17px`,
              boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.3)",
              cursor: "pointer",
              zIndex: 11000,
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setShow((prev) => !prev);
            }}
          >
            <PriorityHighRoundedIcon
              sx={{
                fill: appRedLight,
                height: "33px",
                width: "33px",
                mb: "1px",
              }}
            />
          </Box>
        </Motion>
      </Box>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <ScrollUp />
        <div className="App" style={{ minWidth: "300px" }}>
          <Warning />
          <Router history={history}>
            <div className="container">
              <Switch>
                {routes.map((route, index) => (
                  <BaseRoute
                    exact
                    path={route.path}
                    component={route.element}
                    key={"Route" + index}
                    navbar={route.navbar}
                    elevation={route.elevation ? true : false}
                  />
                ))}
              </Switch>
            </div>
          </Router>
        </div>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
