import React from "react";
import {
  Box,
  Button,
  Container,
  styled,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";
import history, { redirect } from "../Classes/history";
import HelmetComponent from "../Classes/Metadata/HelmetComponent";
import LargeFooter from "../Components/Footers/LargeFooter";
import { ReactComponent as PC404 } from "../../Design/404.svg";
import { articlesOne } from "./ClankyData/texts";
import Article from "../Components/Article";
import { buttonProps, buttonText } from "../Components/TabPanel/Tabs/TabStyles";
import { Link } from "react-router-dom";
import MainBox from "../Components/MainBox";

const PCIcon = () => {
  const editedIcon = React.cloneElement(<PC404 />, {
    style: {
      width: "400px",
      height: "120px",
      fill: "white",
    },
  });
  return <>{editedIcon}</>;
};

const PCIconSmall = () => {
  const editedIcon = React.cloneElement(<PC404 />, {
    style: {
      width: "260px",
      height: "78px",
      fill: "rgb(180,180,180)",
    },
  });
  return <>{editedIcon}</>;
};

const NotFound = () => {
  return (
    <>
      <Box sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}>
        <MainBox height={{ xs: 460, sm: 530, md: 530 }} />
      </Box>
      <HelmetComponent page={"notfound"} />
      <Container component="main" maxWidth="lg" sx={{ position: "relative" }}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            alignItems: "center",
            pt: "120px",
            marginBottom: "160px",
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <PCIcon />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <PCIconSmall />
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Typography
              sx={{
                maxWidth: "300px",
                textAlign: "center",
                fontSize: "24px",
                fontFamily: "RawlineBold",
                color: { xs: "black", sm: "white", md: "white" },
              }}
            >
              Stránka nenalezena
            </Typography>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Typography
              sx={{
                maxWidth: "260px",
                textAlign: "center",
                fontSize: "12px",
                fontFamily: "RawlineMedium",
                color: { xs: "black", sm: "white", md: "white" },
              }}
            >
              Omlouváme se, ale stránku se bohužel nepodařilo nalézt.
            </Typography>
          </Box>
          <Box
            sx={{ marginTop: "40px", textDecoration: "none" }}
            component={Link}
            href="/"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ ...buttonProps, height: "46px", width: "240px" }}
              onClick={() => {
                redirect("/");
              }}
            >
              <Typography sx={{ ...buttonText, fontSize: "12px" }}>
                Zpět na vyhledávač exekucí
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            marginLeft: { xs: "15px", sm: "35px" },
            marginRight: "15px",
            alignItems: { xs: "center", sm: "normal" },
          }}
        >
          <Grid container spacing={4} columns={24} sx={{}}>
            {articlesOne.map((data, index) => (
              <Article
                key={"404" + index}
                data={data}
                index={index}
                sxProps={
                  index == 2
                    ? { display: { xs: "block", sm: "none", md: "block" } }
                    : {}
                }
              />
            ))}
          </Grid>
        </Box>
      </Container>
      <LargeFooter />
    </>
  );
};

export default NotFound;
