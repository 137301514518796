import { darkestBlue, lightestBlue } from "../Main/Styles/constants";
export default {
  palette: {
    primary: {
      light: `${lightestBlue}`,
      main: `${darkestBlue}`,
      dark: "#008394",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff6333",
      main: "#ff3d00",
      dark: "#b22a00",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          /*...(ownerState.variant === "body1" && {
            lineHeight: "15px",
            height: "15px",
          }),*/
        }),
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
};
