import React, { lazy } from "react";
import Loadable from "../Main/Components/Modal/Loadable";

const ObchodniPodminky = Loadable(
  lazy(() => import("../Main/Pages/ObchodniPodminky/ObchodniPodminky"))
);
const OchranaUdaju = Loadable(
  lazy(() => import("../Main/Pages/OchranaUdaju/OchranaUdaju"))
);

const legalRoutes = [
  {
    path: "/podminky",
    element: <ObchodniPodminky />,
  },
  {
    path: "/zasady",
    element: <OchranaUdaju />,
  },
];

export default legalRoutes;
