import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { darkestBlue, lightestBlue } from "../../Styles/constants";
import "./LoadingPage.css";

export default function LoadableLoader() {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#406cc4",
        opacity: 0.85,
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        zIndex: 12000,
        top: 0,
      }}
    >
      <div className="loader" />
    </div>
  );
}
