import React from "react";
import { Typography, Box } from "@mui/material";

import { ReactComponent as IconArticle } from "../../../Design/Articles/Icons/IconArticle.svg";
import { ReactComponent as IconPhone } from "../../../Design/Articles/Icons/IconPhone.svg";
import { ReactComponent as IconPaper } from "../../../Design/Articles/Icons/IconPaper.svg";
import { articleBlue, darkestBlue } from "../../Styles/constants";

function getIcon(info) {
  switch (info) {
    case "Article":
      return <IconArticle />;
    case "Phone":
      return <IconPhone />;
    case "Paper":
      return <IconPaper />;
    default:
      return <IconArticle />;
  }
}

const SmallIcon = ({ icon, color }) => {
  const editedIcon = React.cloneElement(icon, {
    style: {
      fill: color ? color : darkestBlue,
      height: "13px",
      width: "13px",
      marginRight: "5px",
      marginTop: "1.5px",
    },
  });
  return <>{editedIcon}</>;
};

//icon = iconName (Article/Phone/Paper)
//title = blue text
export default function BlueSmallHead(props) {
  const { icon, title, color } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        maxWidth: "280px",
        marginBottom: "10px",
      }}
    >
      <SmallIcon icon={getIcon(icon)} color={color} />
      <Typography
        variant="h4"
        sx={color ? { ...articleBlue, color: color } : { ...articleBlue }}
      >
        {title}
      </Typography>
    </Box>
  );
}
