import { Grid, Box, Typography, Link } from "@mui/material";

import FotoAuto from "../../../Design/Articles/Photos/FotoAuto.webp";
import FotoBusiness from "../../../Design/Articles/Photos/FotoBusiness.webp";
import FotoExe from "../../../Design/Articles/Photos/FotoExe.webp";
import FotoNajemnik from "../../../Design/Articles/Photos/FotoNajemnik.webp";
import FotoPartner from "../../../Design/Articles/Photos/FotoPartner.webp";
import FotoPujcka from "../../../Design/Articles/Photos/FotoPujcka.webp";
/* */
import FotoUcet from "../../../Design/Articles/Photos/FotoChranenyUcet.webp";
import FotoExekutor from "../../../Design/Articles/Photos/Fotoexekutor.webp";
import FotoJakNaExe from "../../../Design/Articles/Photos/Fotojaknaexe.webp";
import FotoMatka from "../../../Design/Articles/Photos/Fotomatka.webp";
import FotoJakUsetrit from "../../../Design/Articles/Photos/FotoJakUsetrit.webp";
import FotoCenik from "../../../Design/Articles/Photos/FotoCenik.webp";
/* PNG */
import FotoAutopng from "../../../Design/Articles/Photos/FotoAuto.png";
import FotoBusinesspng from "../../../Design/Articles/Photos/FotoBusiness.png";
import FotoExepng from "../../../Design/Articles/Photos/FotoExe.png";
import FotoNajemnikpng from "../../../Design/Articles/Photos/FotoNajemnik.png";
import FotoPartnerpng from "../../../Design/Articles/Photos/FotoPartner.png";
import FotoPujckapng from "../../../Design/Articles/Photos/FotoPujcka.png";
/* */
import FotoUcetpng from "../../../Design/Articles/Photos/FotoChranenyUcet.png";
import FotoExekutorpng from "../../../Design/Articles/Photos/Fotoexekutor.png";
import FotoJakNaExepng from "../../../Design/Articles/Photos/Fotojaknaexe.png";
import FotoMatkapng from "../../../Design/Articles/Photos/Fotomatka.png";
import FotoJakUsetritpng from "../../../Design/Articles/Photos/FotoJakUsetrit.png";
import FotoCenikpng from "../../../Design/Articles/Photos/FotoCenik.png";

import { articleBase, articleTitle, articleBlue } from "../../Styles/constants";

const buttonText = {
  ...articleBlue,
  color: "#fff",
  textTransform: "none",
  textAlign: "center",
  //lineHeight: "40px",
  //height: "40px",
  marginTop: "2px",
};

export const articlesOne = [
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Zamítnutí
        <br />
        půjčky
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Zápis v registru exekucí je jedním z nejčastějších důvodů zamítnutí
        půjčky. A co hůř, o zápisu v evidenci exekucí ani nemusíte vědět. V níže
        uvedeném článku najdete příběh Jany, která podobnou situaci zažila.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoPujcka,
    imagePhotoPng: FotoPujckapng,
    id: "pujcka",
  },
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Prověrka
        <br />
        životního partnera
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        V nejednom vztahu jsou finance toho druhého záležitostí, o které se
        nemluví. Situace, kdy po pár měsících společného soužití vyplynula
        najevo nepříjemná zjištění o závazcích toho druhého se přihodila paní
        Martině. Více naleznete v článku.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoPartner,
    imagePhotoPng: FotoPartnerpng,
    id: "proverka",
  },
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Nákup auta z<br />
        druhé ruky
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Pokud kupujete ojetý vůz, pak Vám doporučujeme si prodávajícího vždy
        prověřit. Auto Vám totiž mohou zabavit. Přesně to se stalo paní Zuzaně v
        našem článku.{" "}
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoAuto,
    imagePhotoPng: FotoAutopng,
    id: "auto",
  },
];

export const articlesTwo = [
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Nájemník
        <br />s exekucí
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Výběr nájemníka je po špatných zkušenostech pro mnoho pronajímatelů
        citlivé téma. S nájemníkem, který zatajoval mnoho exekucí se setkala
        paní Pavlína. Na tohle setkání bohužel už nezapomene.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoNajemnik,
    imagePhotoPng: FotoNajemnikpng,
    id: "najemnik",
  },
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Exekuce
        <br />v podnikání
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        V podnikání je třeba podstupovat rizika, některým se však lze předem
        vyhnout. V níže přiloženém článku naleznete příběh Petra, který ve svém
        podnikání zvolil cestu důvěřivosti. To se mu bohužel stalo osudným.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoBusiness,
    imagePhotoPng: FotoBusinesspng,
    id: "podnikani",
  },
];

export const articlesThree = [
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Pravidla
        <br />
        exekuce
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Každé řízení má svá pravidla a u exekučního řízení tomu není jinak. V
        níže uvedeném článku jsme pro vás vybrali pár důležitých věcí, jejichž
        znalost Vás může uchránit od případné újmy.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoExe,
    imagePhotoPng: FotoExepng,
    id: "exekuce",
  },
];

export const articlesFour = [
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Chráněný účet v exekučním řízení
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Nečekané zablokování účtů ze strany exekutora dlužníka zpravidla velmi
        paralyzuje. Dotčené osobě rázem zmizí možnost využívat bezhotovostních
        plateb, což může vést ke vzniku dalšího zadlužení. Ochranou dlužníka
        proti těmto důsledkům je chráněný účet.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoUcet,
    imagePhotoPng: FotoUcetpng,
    id: "chranenyucet",
  },
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Co vám nesmí exekutor zabavit
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Pokud na Vás věřitel poštve exekutora, je dobré být si vědom hranic, za
        které exekutor nemůže. V tomto článku se dočtete, jak exekuční řízení
        probíhá, jaký majetek vám může exekutor zabavit a na co už nesmí
        sáhnout.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoExekutor,
    imagePhotoPng: FotoExekutorpng,
    id: "prubehexekuce",
  },
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Jak se vyhnout exekuci a exekutorovi
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Na české domácnosti je s ohledem na současnou situaci, při které dochází
        k navyšování životních nákladů vyvíjen nebývalý nátlak. Tato skutečnost
        může v mnohých domácnostech vést až k riziku započetí exekučního řízení.
        Tento článek se věnuje tomu, jak se exekuci vyvarovat.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoJakNaExe,
    imagePhotoPng: FotoJakNaExepng,
    id: "vyhnutiexekuce",
  },
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Exekuce a náhradní výživné
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Spousta matek samoživitelek se ze strany svých bývalých partnerů setkává
        s problematickým vyplácením výživného, a to i v případech, kdy výši
        výživného určil a nařídil soud. Jak v tomto případě postupovat a na co
        máte nárok se dozvíte v tomto článku.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoMatka,
    imagePhotoPng: FotoMatkapng,
    id: "nahradnivyzivne",
  },
  {
    icon: "Article",
    description: "Článek",
    title: (
      <Typography variant="h3" sx={articleTitle}>
        Tři rady, jak ušetřit v exekuci
      </Typography>
    ),
    text: (
      <Typography sx={articleBase}>
        Ať už jste do exekuce spadli z jakéhokoliv důvodu, je dobré znát
        způsoby, jak z ní vyváznout bez zbytečných obtíží, důstojně a co
        nejlevněji. V tomto článku bychom Vám rádi nabídli 4 rady, které Vám
        pomůžou snáze projít exekučním řízením.
      </Typography>
    ),
    button: true,
    buttonText: <Typography style={buttonText}>Přečíst</Typography>,
    buttonContained: false,
    image: true,
    imagePhoto: FotoJakUsetrit,
    imagePhotoPng: FotoJakUsetritpng,
    id: "projitexekucilevneji",
  },
];
