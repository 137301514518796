import React, { Component } from "react";

export default class ScrollUp extends Component {
  componentDidMount() {
    setTimeout(() => {
      const body = document.querySelector("#root");
      body.scrollIntoView(
        {
          behavior: "auto",
        },
        0
      );
    }, 500);
  }

  render() {
    return <></>;
  }
}
