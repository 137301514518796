import { Box } from "@mui/material";
import React from "react";
import { blueGradient, darkestBlue, lightestBlue } from "../Styles/constants";
import { centerGridContent } from "./TabPanel/Tabs/TabStyles";

export default function MainBox({ height = { xs: 0, sm: 730, md: 460 } }) {
  return (
    <Box
      sx={{
        position: "absolute",
        alignContent: "center",
        justifyContent: "center",
      }}
      style={{
        width: `100%`,
      }}
      {...centerGridContent}
    >
      <Box
        sx={{
          background: blueGradient,
          minHeight: height,
          maxHeight: height,
          height: height,
          borderRadius: "10px",
          marginBottom: "0px",
          marginTop: "-20px",
          maxWidth: 1200,
          mx: "5px",
          width: { md: "98%", lg: `99%`, xl: `99%` },
        }}
      />
    </Box>
  );
}
