import React, { Suspense } from "react";

const LoadableInvisible = (Component) => (props) =>
  (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );

export default LoadableInvisible;
