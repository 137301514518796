import { createBrowserHistory } from "history";

export function redirect(to, props) {
  let history = createBrowserHistory();
  let data = props ? props : null;
  history.push({ pathname: to, state: data });
  history.go();
}

export function replace(url) {
  let history = createBrowserHistory();
  history.push({ pathname: url });
}

export function isState() {
  let history = createBrowserHistory();
  return history.location && history.location.state ? true : false;
}

export function getState() {
  let history = createBrowserHistory();
  if (!history.location) {
    return null;
  }
  return history.location.state;
}

export default createBrowserHistory();
