import { Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { redirect } from "../Classes/history";

// MAIN PAGES

export const MainTitle = {
  fontSize: { xs: "28px", sm: 37 },
  fontFamily: "MonserratBold",
  textAlign: "center",
};

export const SmallTitle = {
  fontSize: { xs: 15, sm: 15 },
  fontFamily: "RawlineMedium",
  textAlign: "center",
};

export const appGreyOutline = "#F9F9FA";
export const appGrey = "#444444";
export const appBlack = "#252525";
export const appWhite = "#F3F3F3";
export const appRed = "#FB002A";
export const appRedLight = "#FF5555";
export const appGreen = "#14A614";
export const appDarkGreen = "#026A02";
export const darkestBlue = "#3B57B0";
export const lightestBlue = "#358DE0"; //#2EAAFA
export const blueGradient = `linear-gradient(90deg, ${darkestBlue} 0%, ${lightestBlue} 100%)`;
// ALL TITLES

export const titleText = {
  fontSize: { xs: 24, sm: 30 },
  fontFamily: "MonserratBold",
  textAlign: { xs: "center", sm: "left" },
};

// ARTICLES

export const articleBlue = {
  color: darkestBlue,
  fontSize: 13,
  fontFamily: "MonserratExtra",
};

export const articleTitleText = {
  fontSize: 23,
  fontFamily: "MonserratBold",
  lineHeight: "24px",
};

export const articleTitle = {
  ...articleTitleText,
  marginBottom: "12px",
  maxWidth: "280px",
};

export const articleBaseText = {
  fontSize: 12,
  fontFamily: "RawlineMedium",
  color: "#5D5D5D",
};

export const articleBase = {
  ...articleBaseText,
  lineHeight: "15px",
  minHeight: { xs: "50px", sm: "100px" },
};

// Buttons
export const buttonStyleClanky = {
  maxWidth: "110px",
  maxHeight: "30px",
  height: "30px",
  borderRadius: "7px",
  marginTop: "20px",
  backgroundColor: darkestBlue,
  "&:hover": {
    backgroundColor: lightestBlue,
  },
};

export const buttonText = {
  ...articleBlue,
  color: "#fff",
  textTransform: "none",
  textAlign: "center",
  //lineHeight: "40px",
  //height: "40px",
  marginTop: "2px",
};

export function ButtonContained(props) {
  const { text } = props;
  return <Button variant="contained">{text}</Button>;
}

export function ButtonOutlined(props) {
  const { text } = props;
  return <Button variant="outlined">{text}</Button>;
}

export function ButtonContainedMotion(props) {
  const { text, style, href = "" } = props;
  return (
    <Button
      component={motion.button}
      whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 400, damping: 40 }}
      variant={"contained"}
      sx={{ ...buttonStyleClanky, ...style }}
      onClick={() => {
        redirect(href);
      }}
    >
      <a
        style={{
          textDecoration: "none",
        }}
        href={href}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Typography sx={{ ...buttonText, paddingTop: "1px" }}>
          {text}
        </Typography>
      </a>
    </Button>
  );
}
