import baseRoutes from "./BaseRoutes";
import shopRoutes from "./ShopRoutes";
import legalRoutes from "./LegalRoutes";
import NotFound from "../Main/Pages/NotFound";

const routes = [
    ...baseRoutes,
    ...shopRoutes,
    ...legalRoutes,
    { path: "*", element: <NotFound />, navbar: true, secure: false, elevation: true, },
  ];
  
  export default routes;