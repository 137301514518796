import React, { Component } from "react";
import { Grid, Box, Typography, Link } from "@mui/material";
import { ReactComponent as LogoSvg } from "../../../Design/Logo.svg";
import { ReactComponent as Facebook } from "../../../Design/MediaIcons/Facebook.svg";
import { ReactComponent as Linkedin } from "../../../Design/MediaIcons/Linkedin.svg";
import PaymentsFooter from "./PaymentsFooter";
import { redirect } from "../../Classes/history";
import {
  appRed,
  blueGradient,
  darkestBlue,
  lightestBlue,
} from "../../Styles/constants";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const LogoVector = () => {
  const editedIcon = React.cloneElement(<LogoSvg />, {
    style: {
      height: "38px",
      maxWidth: "127px",
      fill: "white",
    },
  });
  return <>{editedIcon}</>;
};

const WhiteIcon = (props) => {
  const editedIcon = React.cloneElement(props.icon, {
    style: props.margin
      ? {
          marginRight: "10px",
          fill: "rgba(255,255,255,1)",
          height: "30px",
          width: "30px",
        }
      : {
          fill: "rgba(255,255,255,1)",
          height: "30px",
          width: "30px",
          marginRight: "20px",
        },
  });
  return <>{editedIcon}</>;
};

const marginTotal = { xs: "-15px", sm: "20px" };
const SCREEN_SIZE = 24;
const smallTexts = {
  color: "white",
  fontSize: 13,
  fontFamily: "RawlineMedium",
  fontStyle: "normal",
};
const gridItem = {
  display: { xs: "none", sm: "block" },
  marginTop: "25px",
  paddingLeft: { sm: "40px", md: "40px", lg: "60px" },
};

const produkty = [
  { text: "Registr exekucí online", ref: "/" },
  { text: "Mobilní aplikace", ref: "/app" },
  { text: "Paragraf software", ref: "/firmy" },
];
const clanky = [
  { text: "Zamítnutí půjčky", ref: "pujcka" },
  { text: "Prověrka životního partnera", ref: "proverka" },
  { text: "Koupě vozu", ref: "auto" },
  { text: "Nájemník s exekucí", ref: "najemnik" },
  { text: "Obchodní partner", ref: "podnikani" },
  { text: "Povinnost v exekuci", ref: "exekuce" },
];
const rychleMenu = [
  { text: "Vyhledávací formulář", remove: true, ref: "/" },
  { text: "Časté dotazy", ref: "/dotazy" },
  { text: "Mobilní aplikace", remove: true, ref: "/app" },
  { text: "Řešení pro firmy", remove: true, ref: "/firmy" },
  { text: "Obchodní podmínky", ref: "/podminky" },
  { text: "Zásady ochrany soukromí", ref: "/zasady" },
];

function Title({ text }) {
  return (
    <Typography
      variant="body2"
      sx={{
        color: "white",
        fontSize: 17,
        marginBottom: "25px",
        fontFamily: "MonserratBold",
      }}
    >
      {text}
    </Typography>
  );
}

export default class LargeFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color ? this.props.color : false,
    };
  }

  getRemove(data) {
    if (data.remove) {
      return "none";
    }
    return "list-item";
  }

  render() {
    return (
      <footer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={0}
            columns={SCREEN_SIZE}
            style={{
              background: this.state.color ? this.state.color : blueGradient,
              borderRadius: "10px",
              marginBottom: "0px",
              marginTop: "60px",
              maxWidth: 1200,
              padding: "40px",
              minWidth: "290px",
            }}
            sx={{
              marginLeft: { xs: "6px", sm: "12px" },
              marginRight: { xs: "6px", sm: "12px" },
              minHeight: { xs: 160, sm: 400 },
              maxHeight: { xs: 160, sm: 400 },
              height: { xs: 160, sm: 400 },
            }}
          >
            <Grid
              item
              xs={0}
              sm={8}
              md={6}
              lg={6}
              sx={{
                ...gridItem,
              }}
            >
              <Title text={"Produkty"} />
              {produkty.map((data, i) => (
                <Link
                  sx={{
                    display: "list-item",
                    marginRight: "50px",
                    ...smallTexts,
                  }}
                  key={"FooterProd" + i}
                  underline="none"
                  href={`${data.ref}`}
                >
                  {data.text}
                </Link>
              ))}
            </Grid>
            <Grid
              item
              xs={0}
              sm={0}
              md={6}
              lg={6}
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                marginTop: "25px",
                paddingLeft: { sm: "40px", md: "40px", lg: "60px" },
              }}
            >
              <Title text={"Články"} />
              {clanky.map((data, i) => (
                <Link
                  sx={{
                    display: "list-item",
                    marginRight: "30px",
                    ...smallTexts,
                  }}
                  key={"FooterClanky" + i}
                  underline="none"
                  href={`/clanek?title=${data.ref}`}
                >
                  {data.text}
                </Link>
              ))}
            </Grid>
            <Grid
              item
              xs={0}
              sm={8}
              md={6}
              lg={6}
              sx={{
                ...gridItem,
              }}
            >
              <Title text={"Rychlé menu"} />
              {rychleMenu.map((data, i) => (
                <Link
                  sx={{
                    marginRight: "20px",
                    ...smallTexts,
                    display: {
                      sm: this.getRemove(data),
                      md: "list-item",
                    },
                  }}
                  key={"FooterClanky" + i}
                  underline="none"
                  href={`${data.ref}`}
                >
                  {data.text}
                </Link>
              ))}
            </Grid>
            <Grid
              item
              xs={24}
              sm={8}
              md={6}
              lg={6}
              sx={{
                ...gridItem,
              }}
            >
              <Title text={"Adresa"} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <LocationOnIcon
                  sx={{
                    fill: "white",
                    width: "16px",
                    height: "16px",
                    marginLeft: "-21px",
                    mt: "1px",
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() => {
                    if (window) {
                      window.open(
                        "https://goo.gl/maps/y6kmaC7FdMgpNFsR7",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "5px",
                    maxWidth: "195px",
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() => {
                    if (window) {
                      window.open(
                        "https://goo.gl/maps/y6kmaC7FdMgpNFsR7",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                >
                  <address>
                    <Typography sx={{ ...smallTexts }}>Brno</Typography>
                    <Typography sx={{ ...smallTexts }}>
                      Högrova 2862/2
                    </Typography>
                    <Typography sx={{ ...smallTexts }}>612 00</Typography>
                  </address>
                </Box>
              </Box>
              <Box sx={{ marginTop: "10px" }}>
                <Link
                  sx={{
                    marginRight: "20px",
                    ...smallTexts,
                    display: "list-item",
                  }}
                  key={"FooterKontaktLink"}
                  underline="none"
                  href={`/kontakt`}
                >
                  Kontakt
                </Link>
              </Box>
              <Box sx={{ marginTop: "0px" }}>
                <Link
                  sx={{
                    marginRight: "20px",
                    ...smallTexts,
                    display: "list-item",
                  }}
                  key={"FooterCenikLink"}
                  underline="none"
                  href={`/cenik`}
                >
                  Ceník
                </Link>
              </Box>
            </Grid>
            {/*//////// ICONS ////////*/}
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={6}
              sx={{ marginTop: marginTotal }}
            >
              <Box
                sx={{
                  paddingLeft: { xs: 0, sm: "40px", md: "40px", lg: "60px" },
                }}
              >
                <Box
                  style={{
                    height: "38px",
                    width: "127px",
                  }}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => redirect("/")}
                >
                  <LogoVector />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={0}
              sm={8}
              md={6}
              lg={6}
              sx={{ marginTop: marginTotal }}
            ></Grid>
            <Grid
              item
              xs={0}
              sm={0}
              md={6}
              lg={6}
              sx={{ marginTop: marginTotal }}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-end", sm: "flex-start" },
                marginTop: marginTotal,
                paddingLeft: { xs: 0, sm: "42px", md: "42px", lg: "62px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: { xs: "-10px", sm: "0px" },
                }}
              >
                <Box
                  component={Link}
                  href={
                    "https://www.facebook.com/profile.php?id=100093735735331"
                  }
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (window) {
                      window.open(
                        "https://www.facebook.com/profile.php?id=100093735735331",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                >
                  <WhiteIcon margin={true} icon={<Facebook />} />
                </Box>
                <Box
                  component={Link}
                  href={"https://www.linkedin.com/company/98096117/"}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (window) {
                      window.open(
                        "https://www.linkedin.com/company/98096117/",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                >
                  <WhiteIcon icon={<Linkedin />} />
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={24}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "25px",
                  fontFamily: "RawlineMedium",
                }}
                sx={{ fontSize: { xs: 10, sm: 12 }, minWidth: "235px" }}
              >
                {`© ${new Date().getFullYear()} Metastream Enterprises s.r.o. | Högrova 2862/2 | Královo Pole | 612 00 Brno | IČO: 19462395 | `}
                <a
                  href="mailto:podpora@vyhledavaniexekuci.cz"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontFamily: "RawlineMedium",
                  }}
                >
                  podpora@vyhledavaniexekuci.cz
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <PaymentsFooter />
      </footer>
    );
  }
}
