export const metadata = [
  {
    page: "MainPage",
    title: "Vyhledávač osob v oficiálním registru exekucí | Online",
    description:
      "Prověřte osoby i firmy okamžitě prostřednictvím Vyhledávače exekucí | Anonymně bez nutnosti registrace | PDF výpis exekuce on-line | K vyhledání postačí zadat jméno, RČ nebo IČO.",
    keywords:
      "exekuce, CEE, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí, exekuce info, exekuce registr, exekuce výpis, prověrka exekucí, kontrola exekucí",
  },
  {
    page: "AppPage",
    title: "Aplikace k prověřce osob v Oficiálním Registru Exekucí",
    description:
      "Zjednodušte si prověřování osob mobilní aplikací. Zajišťujeme snadné vyhledávání každého včetně podnikatelů a firem. Aplikace je dostupná na telefonech s operačními systémy iOS i Android.",
    keywords:
      "Mobilní aplikace, exekuce, CEE, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí, exekuce info, exekuce registr, exekuce výpis, prověrka exekucí, kontrola exekucí",
  },
  {
    page: "FirmyPage",
    title: "Paragraf Software | Podnikatelský nástroj k prověření Exekuce",
    description:
      "Prověřujte osoby Centrální evidenci exekucí a Insolvenčním rejstříku v Česku i na Slovensku. s nástrojem určeným pro podnikatele. K vyhledání osoby vám postačí znát její jméno nebo IČO.",
    keywords:
      "Mobilní aplikace, exekuce, CEE, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí, exekuce info, exekuce registr, exekuce výpis, prověrka exekucí, kontrola exekucí",
  },
  {
    page: "Kontakt",
    title:
      "Kontakt | Metastream Enterprises s.r.o. | www.vyhledavaniexekuci.cz",
    description:
      "Metastream Enterprises s.r.o. | Sídlo: Högrova 2862/2 Královo Pole | 612 00 Brno | IČO: 19462395 | Tel: +420 737 758 205 | podpora@vyhledavaniexekuci.cz | Obchodní podmínky",
    keywords:
      "Metastream, kontakt, informace, adresa, obchodní podmínky, ochrana soukromí",
  },
  {
    page: "CasteDotazy",
    title: "Časté dotazy na službu | Tematické články a rady",
    description:
      "Co je Centrální evidence exekucí? | Jak jsou lustrace zpoplatněny? | Kdy dochází k vymazání údajů z CEE? | K čemu Centrální evidence slouží? | Co v Centrální evidenci exekucí nenajdu?",
    keywords: "články, časté dotazy, informace, témata exekuce",
  },
  {
    page: "pujcka",
    title: "Zamítnutí půjčky z důvodu exekuce | Častý problém České republiky",
    description:
      "Zápis v registru exekucí je jedním z nejčastějších důvodů zamítnutí půjčky. Věděli jste, že o zápisu v evidenci exekucí ani nemusíte vědět. V článku najdete příběh osoby, která podobnou situaci zažila.",
    keywords:
      "zamítnutí půjčky, exekuce, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí",
  },
  {
    page: "proverka",
    title:
      "Prověrka solventnosti partnera | Postupujte rychle | Anonymně | Online",
    description:
      "Solventnost partnera je v dnešní době tématem. Situace, kdy po pár měsících společného soužití vyplynula najevo nepříjemná zjištění se dotkla paní Martiny. Více naleznete v článku.",
    keywords:
      "partner v exekuci, exekuce, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí",
  },
  {
    page: "auto",
    title: "Nákup auta od člověka s exekucí | Nenechte si vůz zabavit!",
    description:
      "Pokud kupujete ojetý vůz, pak Vám doporučujeme si prodávajícího vždy prověřit. Auto Vám totiž mohou zabavit. Přesně to se stalo paní Zuzaně v našem článku.",
    keywords:
      "prodej auta, zabavení auta, exekuce, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí",
  },
  {
    page: "najemnik",
    title: "Nájemník s exekucí může být problém | O věci v bytě můžete přijít",
    description:
      "Výběr nájemníka je po špatných zkušenostech pro mnoho pronajímatelů citlivé téma. S nájemníkem, který zatajoval mnoho exekucí se setkala paní Pavlína. Na tohle setkání už nezapomene.",
    keywords:
      "Nájemník s exekucí, exekuce, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí",
  },
  {
    page: "podnikani",
    title: "Exekuce v podnikání | Nepoctivý kolega je velký problém",
    description:
      "V podnikání je třeba podstupovat rizika, některým se však lze předem vyhnout. V níže přiloženém článku naleznete příběh Petra, který ve svém podnikání zvolil cestu důvěřivosti.",
    keywords:
      "Exekuce v podnikání, exekuce, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí",
  },
  {
    page: "exekuce",
    title:
      "Pravidla exekuce která je třeba mít na paměti | Vyhněte se problémům",
    description:
      "Každé řízení má svá pravidla a u exekučního řízení tomu není jinak. V níže uvedeném článku jsme pro vás vybrali pár důležitých věcí, jejichž znalost Vás může uchránit od případné újmy.",
    keywords:
      "Pravidla exekuce, registr exekucí, rejstřík exekucí, centrální evidence exekucí, výpis exekucí, seznam exekucí",
  },
  {
    page: "notfound",
    title: "404 Stránka nenalezena | www.vyhledavaniexekuci.cz",
    description:
      "Je nám líto, požadovaná stránka neexistuje. Zkontrolujte prosím správné zadání adresy. Potřebujete vyhledat exekuci? Pomůžeme vám najít, co hledáte.",
    keywords: "Stránka nenalezena, page 404, Chyba",
  },
  {
    page: "mezifaze1",
    title:
      "Váš výpis je na cestě | Jak dále postupovat? | vyhledavaniexekuci.cz",
    description:
      "Příprava výpisu exekucí může občas z důvodu nečekaného prodlení na straně registru exekucí chvíli trvat. Hned jak bude váš výpis k dispozici, zašleme vám jej do emailu, který jste zadali při objednávce.",
    keywords: "",
  },
  {
    page: "mezifaze2",
    title: "Váš výpis je na cestě | Časté dotazy | Další vyhledávání",
    description:
      "Děkujeme! Hned jak obdržíme platbu, můžete svůj výpis očekávat v emailu, který jste zadali při objednávce.",
    keywords: "",
  },
  {
    page: "mezifaze3",
    title: "Příprava výpisu | Časté dotazy | Vyhledat další osobu",
    description:
      "Děkujeme! Hned jak obdržíme platbu od mobilního operátora, můžete svůj výpis očekávat na emailu, který jste zadali při objednávce.",
    keywords: "",
  },
  {
    page: "shop",
    title:
      "Objednávka výpisu | Fakturační údaje | Platební metoda | Email zákazníka",
    description:
      "Pro úspěšné dokončení objednávky prosím, vyberte platební metodu a zadejte email, na který si přejete zaslat výpis s informacemi.",
    keywords: "",
  },
  {
    page: "shopdetail",
    title:
      "Objednávka detailu | Fakturační údaje | Platební metoda | Email zákazníka",
    description:
      "Pro úspěšné dokončení objednávky prosím zvolte, u kterých exekucí si přejete zobrazit detail, vyberte způsob platby a zadejte email, na který si přejete zaslat výpis s informacemi.",
    keywords: "",
  },
  {
    page: "vysledek",
    title: "Výsledek kontroly exekucí | www.vyhledavaniexekuci.cz",
    description:
      "Slíbený výsledek vyhledávání z Centrální evidence exekucí jsme pro vás přichystali na této stránce.",
    keywords: "",
  },
  {
    page: "failed",
    title: "Platba se nezdařila | www.vyhledavaniexekuci.cz",
    description:
      "Při placení se vyskytly neočekávané potíže. Opakujte platbu a zkontrolujte, zda jste zadali všechny potřebné údaje správně.",
    keywords: "",
  },
];
