import React, { Suspense } from "react";
import LoadableLoader from "./LoadableLoader";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadableLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
