import { metadata } from "./Metadata";
import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

const preTags = ["", "twitter:", "og:"];

function getData(page) {
  let final = metadata[0];
  metadata.map((data) => {
    if (data.page == page) {
      final = data;
    }
  });
  return final;
}

export default class HelmetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
    };
  }

  render() {
    let { page } = this.state;
    return (
      <>
        {page &&
          preTags.map((tag, index) => {
            if (tag !== "") {
              return (
                <Helmet key={"HelmetMeta" + index}>
                  <meta name={tag + "title"} content={getData(page).title} />
                  <meta
                    name={tag + "description"}
                    content={getData(page).description}
                  />
                </Helmet>
              );
            } else {
              return (
                <Helmet key={"HelmetMeta" + index}>
                  <title>{getData(page).title}</title>
                  <meta name="title" content={getData(page).title} />
                  <meta
                    name="description"
                    content={getData(page).description}
                  />
                </Helmet>
              );
            }
          })}
      </>
    );
  }
}
